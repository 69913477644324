<template lang="pug">
div
  h1 {{ $t('note.created') }}
  div(v-if="data")
    blockquote.copy-text.tooltipped(
      @click="copyText"
      v-html="buildResponseText"
      data-position="bottom"
      data-tooltip="In Zwischenablage kopieren"
    )

    button.btn.green.btn-large(
      @click="copyUrl()"
    ) Nur URL kopieren
    div.separator

    QrCode(
      label="QR-Code"
      :text="url"
      :subtext="buildResponseText"
    )

    button.btn.blue(
      @click="copyText"
    ) Text in Zwischenablage kopieren
    div.separator

    button.btn.blue(
      @click="copyUrlAndPassword"
    ) URL & Passwort kopieren
    div.separator

    button.btn.red(
      @click="deleteNote"
    ) Notiz löschen
    div.separator

  div(v-else)
    div error
</template>
<script>
import axios from 'axios'
import Api from '@/scripts/Api'
import QrCode from "@/components/QrCode"

export default {
  data () {
    return {
      data: null,
      leavable: false,
    }
  },

  components: {
    QrCode
  },

  computed: {
    url () {
      const res = JSON.parse(this.data.res)
      const uid = res.uid
      const key_start = res.key_start
      const origin = window.location.origin
      const path = this.$router.resolve({ name: 'OpenNote' }).href
      const sep = Api.slug_separator
      const key_frontend = this.$store.state.key_frontend

      return `${origin}${path}#${uid}${sep}${key_start}${sep}${key_frontend}` 
    },

    buildResponseText () {
      let url = this.url
      let pw = this.getPassword()
      let pw_text = pw ? this.$t('note.share.password.long', {pw}) : ''

      return this.$t('note.share.text.long', {url, pw_text})
    },
  },

  created () {
    this.data = this.$route.params

    if (this.canLeave()) {
      this.$router.push({
        name: 'CreateNote'
      })
    }
    
    window.addEventListener('beforeunload', (event) => {
      if (this.cantLeave()) {
        event.returnValue = this.$t('confirm_leave')
      }
    })

  },
  
  mounted () {
    // Tooltipps init
    // var elems = document.querySelectorAll('.tooltipped');
    // window.M.Tooltip.init(elems, {});
  },

  beforeRouteLeave (to, from, next) {
    // He Can Leave
    if (this.canLeave() || this.askWindowLeave()) {
      this.resetNote()
      next()
    } else {
      next(false)
    }
  },

  methods: {
    cantLeave () {
      return this?.data?.res && this.leavable == false
    },
    canLeave () {
      return !this.cantLeave()
    },

    askWindowLeave () {
      // if (process.env.NODE_ENV === "development") {
      //   return true
      // }
      return window.confirm(this.$t('confirm_leave'))
    },

    resetNote () {
      this.data = {}
    },

    clearFromHtml (txt) {
      return txt.replace(/<[^>]*>?/gm, '')
    },

    getPassword () {
        let note = JSON.parse(this.data.note)
        let pw = note.password
        return pw
    },

    popupCopyToast (txt) {
      console.log('copy text', txt)
      // window.M.toast({
      //   html: '<span>In Zwischenlage kopiert:<br><blockquote><small>'+txt+'</small></span></blockquote>',
      //   displayLength: 10000,
      //   classes: ''
      // })
    },

    copyText () {
      var txt = this.buildResponseText
      this.$clipboard(this.clearFromHtml(txt))
      this.makeLeavable()
      this.popupCopyToast(txt)
    },

    copyUrlAndPassword () {
      let url = this.url
      let pw = this.getPassword()
      let pw_text = pw ? this.$t('note.share.password.short', {pw}) : ''
      let txt = this.$t('note.share.text.short', {url, pw_text})
      this.$clipboard(this.clearFromHtml(txt))
      this.makeLeavable()
      this.popupCopyToast(txt)
    },

    copyUrl () {
      var txt = '<a>' + this.url + '</a>'
      this.$clipboard(this.clearFromHtml(txt))
      this.makeLeavable()
      this.popupCopyToast(txt)
    },

    deleteNote () {
      const url = Api.domain + Api.url.delete
      const uid = JSON.parse(this.data.res).uid

      axios
        .post(url, { uid })
        .then(res => {
          console.log(res)
        })
        .catch(err => {
          console.log('error' / err)
        })
        // .then(() => this.ajaxAlwaysEnd())

      this.makeLeavable()
    },

    makeLeavable () {
      this.leavable = true
    }
  }


}
</script>