import moment from 'moment'
import i18n from '@/i18n'

// const FORMAT = 'YYYY-MM-DDTHH:mm:ss'
const FORMAT = 'YYYY-MM-DDTHH:mm'
const HOURS_DEFAULT = 3
const MAX_DAYS = 5

var Timer = {

  local_time: function () {
    return moment().local()
  },

  getDateLaterFormat: function () {
    return this.local_time().add(HOURS_DEFAULT, 'hours').format(FORMAT)
  },

  getDateNowFormat: function () {
    return this.local_time().format(FORMAT)
  },

  getDateEndFormat: function () {
    return this.local_time().add(MAX_DAYS, 'days').format(FORMAT)
  },

  getDate: function (d) {
    return moment(d)
  },

  checkDateLater: function (date) {
    let nd = this.getDate(date)
    if (!nd.isValid()) {
      return this.getDate().add(HOURS_DEFAULT, 'hours')
    }
    return nd
  },

  getDateNow: function () {
    return this.getDate(this.getDateNowFormat())
  },

  
  difftime_seconds: function (d) {
    return moment.duration(
      this.checkDateLater(d).diff(this.getDateNow())
    ).asSeconds()
  },

  difftime_string: function  (d) {
    let a = this.checkDateLater(d)
    let b = this.getDateNow() 

    var year = a.diff(b, 'year');
    b.add(year, 'years');

    var month = a.diff(b, 'months');
    b.add(month, 'months');

    var day = a.diff(b, 'days');
    b.add(day, 'days')

    var hour = a.diff(b, 'hours');
    b.add(hour, 'hours')

    var minute = a.diff(b, 'minutes');
    b.add(minute, 'minutes')

    var second = a.diff(b, 'seconds');
    b.add(second, 'seconds')

    return this.strTime({ year, month, day, hour, minute, second })
  },

  difftime_string_compact: function (d) {
    let s = this.difftime_string(d)
    let more_than = s.trim().split(' ').length > 2
    let shorter = s.substring(0, s.indexOf(' ', s.indexOf(' ') + 1))
    return (more_than ? '>' : '') + shorter
  },

  strTime: function  (times) {
    let s = '';
    for(var label in times) {
      let n = times[label]
      if(n <= 0) {
        continue;
      }

      else {
        s += n + ' '
        s += i18n.tc(n == 1 ? label : label+'s') + ' '
        
      }
    }

    return s;
  },
}

export default Timer
