<template lang="pug">
footer.footer
  .nav.pages
    router-link(to="/") {{$t('Home')}}
    router-link(:to="{name: 'CreateNote'}") {{$t('Create Note')}}
    router-link.new(
      :to="{name: 'Chat'}"
      :disabled="production"
    ) {{$t('Chat')}}
  .nav.locales
    a(
      v-for="locale in locales"
      :disabled="i18n.locale === locale"
      @click="changeLocale(locale)"
    ) {{ locale }}
</template>
<script>
import i18n from '@/i18n';

export default {
  data: function() {
    return {
      locales: 'de,en'.split(',')
    }
  },

  computed: {
    i18n: function () {
      return i18n
    },

    production: function () {
      return process.env.NODE_ENV !== 'development'
    }
  },

  methods: {
    changeLocale(locale) {
      i18n.locale = locale;
      // window.M.toast({html: this.$t('lang_changed'), classes: ''});
    }
  }
}
</script>