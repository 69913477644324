<template lang="pug">
button(
    @click="click"
)
  | {{ label }}
</template>
<script>
export default {
    props: {
        label: {
            type: String,
            default: ''
        },
        click: {
            type: Function,
            default: function() {}
        }
    }
}
</script>
