import Vue from "vue";
import Router from "vue-router";

import Home from '../views/Home.vue'
import CreateNote from '../views/Note/CreateNote.vue'
import ShareNote from '../views/Note/ShareNote.vue'
import OpenNote from '../views/Note/OpenNote.vue'

import Chat from '../views/Chat.vue'

Vue.use(Router);

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/create',
    name: 'CreateNote',
    component: CreateNote
  },
  {
    path: '/finish',
    name: 'ShareNote',
    component: ShareNote
  },
  {
    path: '/n',
    name: 'OpenNote',
    component: OpenNote
  },
  {
    path: '/chat',
    name: 'Chat',
    component: Chat,
  },
]

const router = new Router({
  // history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes
})

export default router
