
// domain: 'http://backend.secnote.local:8000',
// domain: 'http://127.0.0.1:8000',

export default {
  domain: process.env.VUE_APP_BACKEND_HOST,
  url: {
    create: '/note-create',
    meta: '/note-meta',
    oad: '/note-open-and-destroy',
    delete: '/note-delete',
  },
  slug_separator: '.',
}
