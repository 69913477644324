import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    key_frontend: String,
    user_salt: String,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
