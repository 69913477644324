import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import i18n from '@/i18n'
import Clipboard from 'v-clipboard'
import VueMeta from 'vue-meta'
import TextareaAutosize from 'vue-textarea-autosize'
import Btn from '@/components/Btn'

import '@/scss/bundle.scss'

Vue.use(Clipboard)
Vue.use(require('vue-moment'))
Vue.use(VueMeta)
Vue.use(TextareaAutosize)

Vue.config.productionTip = false

require('line-awesome/dist/line-awesome/scss/line-awesome.scss')


// Filter
Vue.filter('maskerade', function(text, length, clamp){
  clamp = clamp || '...'
  var node = document.createElement('div')
  node.innerHTML = text
  var content = node.textContent
  const start = content.length / 5
  const end = start + length
  return clamp + content.slice(start, end) + clamp;
});

// Global Button Component
Vue.component('btn', Btn)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
