<template lang="pug">
div
  button.btn.blue(@click="popup") {{ label }}
  .popup(v-if="show")
    .inner
      QrCode.qrcode(
        :text="text"
        :size="150"
        :margin="20"
        level="H"
        color="#2ad075"
        bg-color="#18191b"
      )
      p(v-html="subtext")
      br

      .dont-print
        btn.green.small(:click="print" :label="$t('qr.print')")
        br
        br
        btn.small(:click="popdown" :label="$t('qr.close')")
  .separator
</template>

<script>
import QrCode from 'vue-qrcode-component'

export default {
  props: {
      text: {
          type: String,
          required: true
      },
      subtext: {
          type: String,
          default: null
      },
      label: {
        type: String,
        default: 'QR-Code'
      }
  },
  components: {
    QrCode
  },
  data () {
      return {
          show: false
      }
  },
  methods: {
    popup () {
        this.show = true
    },
    popdown () {
        this.show = false
    },
    print () {
      window.print()
    }
  }
}
</script>