<template lang="pug">
div
  template(v-if="!joined")
    h1 Create Chatroom
    input(type="password")
    div
      button.green(@click="createRoom") Create
    div
      button(@click="joinRoom") joinRoom
  template(v-else)
    form(@submit.prevent="sendChatMessage()")
      input(v-model="message")
      button(type="submit")
    div
      div(v-for="message in messages")
        | {{message}}

</template>

<script>
import Vue from 'vue'
import VueNativeSock from 'vue-native-websocket'

export default {

  data: function() {
    return {
      joined: false,
      message: String(Math.random()),
      messages: [],
      room_id: null,
    }
  },

  created () {
    Vue.use(
      VueNativeSock,
      'ws://127.0.0.1:' + process.env.VUE_APP_WS_PORT,
      {
        format: 'json'
      }
    )

    this.$socket.onmessage = this.onmessage
  },

  methods: {

    createRoom: function() {
      this.$socket.sendObj({
        type: 'create_room',
      })
    },

    joinRoom: function() {
      this.$socket.sendObj({
        type: 'join_room',
      })
    },

    sendChatMessage: function() {
      this.$socket.sendObj({
        type: 'chat_message',
        message: this.message,
      })
    },

    onmessage: function(res) {
      const data = JSON.parse(res.data)
      switch(data.type) {
        case 'chat_message':
          return this.messages.push(data)
        case 'room_created':
          return console.log('room created')
        case 'room_joined':
          this.joined = true
          console.log('room joined')
          break
      }
    },


  },
}
</script>