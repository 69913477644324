import { nanoid } from 'nanoid'
import CryptoJS from 'crypto-js'

export default {
  generateKey: function (length) {
    return nanoid(length) 
  },

  generateBloat: function () {
    return nanoid(256)
  },

  getCryptOrder: function (pw_hash, key_frontend, user_salt) {
    return pw_hash + key_frontend + user_salt
  },

  encrypt: function(content, pw_hash, key_frontend, user_salt) {
    return CryptoJS.AES.encrypt(content, this.getCryptOrder(pw_hash, key_frontend, user_salt)).toString()
  },

  decrypt: function(content, pw_hash, key_frontend, user_salt) {
    return CryptoJS.AES.decrypt(content, this.getCryptOrder(pw_hash, key_frontend, user_salt)).toString(CryptoJS.enc.Utf8)
  },

  hashPassword: function (password) {
    return CryptoJS.SHA512(password).toString()
  },
}
