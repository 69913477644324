<template lang="pug">
span(v-if="renderComponent||true") {{ fake_text | maskerade(maskerade) }}
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    maskerade: {
      type: Number,
      default: 50
    },
    method: {
      type: String,
      default: 'random'
    },
    delay: {
      type: Number,
      default: 250,
    }
  },

  data: function() {
    return {
      fake_text: '',
      renderComponent: true,
    }
  },

  mounted () {
    this.fake_text = this.text
  },

  methods: {
    faker(content) {
      for(var index = 0; index < content.length; index++) {
        this.fakeloop(index, content)
      }
    },

    fakeloop(index, content) {
      let ms = 0
      let delay = this.delay
      let method = this.method

      switch(method) {
        case 'random':
          ms = Math.random() * delay
          break;
        case 'from_left':
          ms = index * delay
          break;
      }

      setTimeout(() => {
        let fake = this.fake_text
        this.renderComponent = false
        this.fake_text = fake.substring(0, index) + content[index] + fake.substring(index + 1)
        this.renderComponent = true
      }, ms)
    },
  },


  watch: {
    text: function(content) {
      this.faker(content)
    },
  }
}
</script>
