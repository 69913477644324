<template lang="pug">
#app
  main
    router-view
  Footer
</template>
<script>
import axios from "axios";
import Footer from '@/components/Footer'

export default {
  name: "App",

  components: {
    Footer
  },

  metaInfo: {
    title: 'Secnote',
    titleTemplate: '%s | create secret notes',
    meta: [
      {
        charset: 'utf-8',
      },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
      },
      {
        name: 'theme-color',
        content: '#000000',
      }
    ]
  },

  created () {

    axios.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      console.log('trigger something here', response)
      return response;
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    });

  }
};
</script>