<template lang="pug">
.loading-wrapper(:class="{ visible: active }")
  .progress
    .indeterminate
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">

@keyframes indeterminate{0%{left:-35%;right:100%}60%{left:100%;right:-90%}100%{left:100%;right:-90%}}

.loading-wrapper {
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    background: rgba(0, 0, 0, 0.4);
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    opacity: 0;
    pointer-events: none;
    transition: 125ms 100ms;

    &.visible {
      opacity: 1;
      pointer-events: all;
    }
}


.progress {
    position: relative;
    height: 4px;
    display: block;
    width: 100%;
    background-color: #3c4a3c;
    border-radius: 2px;
    overflow: hidden;
    margin: 40px;
    max-width: 75vw;

    .indeterminate {
      background-color: #1d9654;

      &:before,
      &:after {
        content: '';
        position: absolute;
        background-color: inherit;
        top: 0;
        left: 0;
        bottom: 0;
        will-change: left, right;
      }

      &:before {
          animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
      }

      &:after {
        animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        animation-delay: 1.15s;
      }
    } 
}
</style>
