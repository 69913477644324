<template lang="pug">
.create-note-wrapper(:class="active ? 'active' : 'inactive'")
  .row
    h1 {{ $t('title') }}
  .row
    NoteTimer(:date="date_later", @date_changed="update_date")
  .row
    .input-field
      textarea-autosize(
        v-model.lazy="content.text",
        autofocus,
        ref="content",
        tabindex="1",
        required,
        placeholder=" ",
        autocomplete="off"
      )
      label {{ $t('priv_note') }}
  .row
    span.checkbox-wrapper.pw-wrapper
      input#pw(
        v-model="use_password",
        type="checkbox",
        checked="checked",
        :class="{ warning: !use_password }",
        tabindex="3",
        name="pwNoSearch"
      )
      | &nbsp;
      label(for="pwNoSearch") {{ $t(use_password ? '' : 'no_pw') }}

      .input-field(v-if="use_password")
        input#password.validate.pw(
          v-model="password",
          :type="show_password ? 'text' : 'password'",
          tabindex="2",
          placeholder=" ",
          autocomplete="off"
          @change="show_password = false"
        )
        label(for="password") {{ $t('pw') }}
        .show(
          v-show="password"
          :class="{active: show_password}"
          :title="$t('show_pw_title')"
          @mousedown="show_password = true"
          @touchstart="show_password = true"
        )
          .las(:class="show_password ? 'la-eye': 'la-eye-slash' ")
      //- span.code pw hash: {{ getPasswordHash() }}
  .row
    .col
      br
      btn.large.green(
        :label="$t('create_note_time', {time: Timer.difftime_string_compact(date_later)})"
        :disabled="formIsInvalid()",
        :click="tryCreateNote"
      )

  .row
    br
    p
      span(v-if="show_add_info")
        | {{ $t('subinfo.more') }}
        a(@click="show_add_info = false") &nbsp;[{{ $t('subinfo.hide') }}] 
      span(v-else)
        | {{ $t('subinfo.less') }}
        a(@click="show_add_info = true") &nbsp;[{{ $t('subinfo.show') }}] 

    div(v-if="show_add_info")
      p {{ $t('note.available_for', {time: Timer.difftime_string(date_later)}) }}
      table.optional.info
        thead
          tr
            th {{ $t('table.key') }}
            th(colspan="2")
              | {{ $t('table.value') }}
              br
              small ({{ $t('subinfo.truncated') }})
        tbody
          tr
            td Key
            td.code
              Animation(:text="key_frontend" :maskerade="12")
            td.clickable(@click="generateKeyFrontend()" :title="$t('table.regenerate')") Reg
          tr
            td Salt
            td.code
              Animation(:text="user_salt" :maskerade="50")
            td.clickable(@click="generateUserSalt()" :title="$t('table.regenerate')") Reg
          tr
            td PwHash
            td.code(colspan="2")
              Animation(:text="getPasswordHash()" :maskerade="50")
          tr
            td Bloat
            td.code
              Animation(:text="content.bloat" :maskerade="50")
            td.clickable(@click="generateBloat()" :title="$t('table.regenerate')") Reg
          tr
            td Enc
            td(colspan="2").code {{ getEncryptedContent() | maskerade(50)}}
  Loading(:active="loading")
</template>

<script>
import axios from "axios"
import Api from "@/scripts/Api"
import Timer from "@/scripts/Timer"
import Cryption from "@/scripts/Cryption"

import NoteTimer from "@/components/NoteTimer"
import Loading from "@/components/Loading"
import Animation from "@/components/Animation"

export default {
  components: {
    Loading,
    NoteTimer,
    Animation,
  },

  data() {
    return {
      content: {
        text: '',
        bloat: ''
      },
      password: "",
      date_later: Timer.getDateLaterFormat(),
      timeout: null,
      active: true,
      loading: false,
      use_password: true,
      show_password: false,
      show_add_info: false,
    };
  },

  computed: {
    url: function () {
      return Api.domain + Api.url.create;
    },

    Timer: function () {
      return Timer;
    },

    Cryption: function () {
      return Cryption;
    },

    key_frontend: {
      get: function () {
        return this.$store.state.key_frontend;
      },
      set: function (state) {
        this.$store.state.key_frontend = state;
      },
    },

    user_salt: {
      get: function () {
        return this.$store.state.user_salt;
      },
      set: function (state) {
        this.$store.state.user_salt = state;
      },
    },
  },

  created () {
    this.generateKeyFrontend();
    this.generateUserSalt(); 
    this.generateBloat();

    ['mouseup','touchend','touchend'].forEach(evt => document.addEventListener(evt, () => this.show_password = false))
  },

  mounted() {
    if (process.env.NODE_ENV != "development") {
      this.initHidingFunctions();
    }
  },

  methods: {

    generateKeyFrontend: function (num = 20) {
      this.key_frontend = Cryption.generateKey(num);
    },

    generateUserSalt: function (num = 64) {
      this.user_salt = Cryption.generateKey(num);
    },

    generateBloat: function () {
      this.content.bloat = Cryption.generateBloat()
    },

    getPasswordHash: function () {
      return Cryption.hashPassword(this.use_password ? this.password : '')
    },

    getEncryptedContent() {
      return Cryption.encrypt(
        JSON.stringify(this.content),
        this.getPasswordHash(),
        this.key_frontend,
        this.user_salt
      );
    },

    update_date(state) {
      this.date_later = state;
    },

    formIsInvalid() {
      return !this.formIsValid();
    },

    formIsValid() {
      return (
        Timer.getDateNowFormat() < this.date_later &&
        !this.loading &&
        this.content.text &&
        (!this.use_password || this.password)
      );
    },

    tryCreateNote() {
      if (!this.loading) {
        this.loading = true;
        this.ajaxCreateNote();
      }
    },

    ajaxCreateNote() {
      let data = {
        content: this.getEncryptedContent(),
        password: this.use_password ? this.getPasswordHash() : "",
        seconds_alive: parseInt(Timer.difftime_seconds(this.date_later)),
        user_salt: this.user_salt,
      };

      axios
        .post(this.url, data)
        .then((res) => this.ajaxSuccess(res))
        .catch((err) => this.ajaxError(err))
        .then(() => this.ajaxAlwaysEnd());
    },

    ajaxSuccess(res) {
      this.$router.push({
        name: "ShareNote",
        params: {
          res: JSON.stringify(res.data),
          note: JSON.stringify({
            password: this.password,
            key_frontend: this.key_frontend,
          }),
        },
      });

      this.resetNote();
    },

    ajaxError(error) {
      console.log(error);
    },

    ajaxAlwaysEnd() {
      this.loading = false;
    },

    resetNote() {
      this.content.text = ''
      this.password = ''
    },

    // Hiding Functions
    initHidingFunctions() {
      this.refresh();
      window.onblur = this.deactivate;
      document.addEventListener("click", this.refresh);
      document.addEventListener("keydown", this.refresh);
    },
    refresh() {
      clearTimeout(this.timeout);
      this.activate();

      let time = 120; // min * sec
      this.timeout = setTimeout(() => {
        this.deactivate();
      }, time * 1000);
    },

    activate() {
      this.active = true;
    },
    deactivate() {
      this.active = false;
    },

  },

};
</script>
