<template lang="pug">
.note-timer(:class="{error: !valid()}")
  p
    span(:title="$t('delete_note')") {{ $t('diff_date_label') }} {{ Timer.difftime_string(date_later) }}
    a(@click="more = true" v-if="!more") [{{ $t('note.time.change') }}]
  transition
    span(v-if="more")
      .input-field
        input(
          v-model="date_later",
          :min="date_min",
          :XXXmax="date_max",
          type="datetime-local"
          placeholder="enddate"
          :title="$t('second_str', { seconds: getSeconds()} )"
          @blur="blur"
          @focus="focus" 
        )
        label {{$t('note.time.expire_date')}}:
      p
        a(@click="more = false" :disabled="!valid()") [{{ $t('note.time.ok') }}]
</template>

<script>
import Timer from '@/scripts/Timer'

export default {

  props: {
    date: {
      Type: String,
      required: true
    }
  },

  data () {
    return {
      date_min: String,
      date_max: String,
      interval: Function,
      focused: false,
      more: false,
    }
  },

  computed: {
    date_later: {
      get: function() {
        return this.date
      },
      set: function(state) {
        this.$emit('date_changed', state)
      }
    },

    Timer: function () {
      return Timer
    },
  },

  created () {
    this.reinitDateMinMax()
    this.startInterval()
  },

  methods: {
    reinitDateMinMax: function () {
      this.date_min = Timer.getDateNowFormat();
      this.date_max = Timer.getDateEndFormat();
    },
    startInterval: function () {
      const seconds = 60
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        if(!this.focused) {
          this.reinitDateMinMax()
        }
      }, seconds * 1000)
    },

    getSeconds: function () {
      return Timer.difftime_seconds(this.date_later)
    },

    change: function (a, b) {
      console.log(a,b)
    },

    valid: function () {
      return this.getSeconds() > 0
    },

    blur: function() {
      if (!this.valid()) {
        this.date_later = Timer.getDateLaterFormat()
      }
      this.focused = true
    },

    focus: function() {
      this.focused = false
    },

  },


}
</script>

<style lang="scss" scoped>
.note-timer {
  transition: color 200ms;

  &.error {
    transition: color 500ms 200ms;
  }
}
</style>
