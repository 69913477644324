<template lang="pug">
div
  template(v-if="response.content")
    h1 Success! Note Opened and destroyed!
    p Decrypted Text
      blockquote.note-message(
        :label="$t('note-message-label')"
        :style="{ 'white-space': 'break-spaces' }"
      ) {{ decrypted.text }}
    p.code Decrypted {{ decrypted }}
    p.code Note {{ response.content }}
  template(v-else)
    div Try to open Note:

    // Password
    input#password.validate(
      v-if="pw_needed"
      v-model="password"
      type='password'
      autocomplete="off"
    )

    // Submit Button
    button.btn.waves-light(
      :disabled="pw_needed && password == ''"
      @click="tryOpenAndDestroyNote"
    ) {{ $t('open_destroy') }}

</template>
<script>
import axios from 'axios'
import Api from '@/scripts/Api'
import Cryption from '@/scripts/Cryption'

export default {
  data () {
    return {
      hash: '',
      uid: '',
      key_start: '',
      pw_needed: false,
      password: null,
      response: {
        content: null,
        user_salt: null,
      },
      decrypted: null,
    }
  },

  computed: {
    key_frontend: {
      get: function() {
        return this.$store.state.key_frontend
      },
      set: function(key) {
        this.$store.state.key_frontend = key
      }
    },
    Cryption: function () {
      return Cryption
    }
  },

  created () {
    this.hash           = window.location.hash.substring(1).split(Api.slug_separator)
    this.uid            = this.hash[0]
    this.key_start      = this.hash[1]
    this.key_frontend   = this.hash[2]

    if (this.uid && this.key_start) {
      this.getMetaInfo()
    }
  },

  mounted: function () {
    this.removeUrlHash()
  },

  methods: {
    decrypt () {
      const dec = Cryption.decrypt(
        this.response.content,
        this.getPasswordHash(),
        this.key_frontend,
        this.response.user_salt
      )
      this.decrypted = JSON.parse(dec)
    },
    getMetaInfo () {
      const url = Api.domain + Api.url.meta
      const uid = this.uid

      this.ajaxAlwaysStart()

      axios
        .post(url, { uid })
        .then(res => {
          this.pw_needed = res.data.pw_needed ?? false
        })
        .catch(err => {
          console.log('error' / err)
          this.pw_needed = false
        })
        .then(() => this.ajaxAlwaysEnd())
    },

    getPasswordHash () {
      return Cryption.hashPassword(this.password)
    },

    removeUrlHash() {
      window.location.hash = '#'
    },

    tryOpenAndDestroyNote () {
      this.ajaxAlwaysStart()

      let url = Api.domain + Api.url.oad
      let params = {
        uid: this.uid,
        key_start: this.key_start
      }

      if (this.pw_needed) {
        params.password = this.getPasswordHash()
      }

      this.ajaxOpenAndDelete(url, params)
    },

    ajaxOpenAndDelete (url, params) {
      axios
        .post(url, params)
        .then(res => {
          // Success
          this.response.content = res.data.content
          this.response.user_salt = res.data.user_salt
          this.decrypt()

          console.log('success!!!')
        })
        .catch(err => {
          console.log('error' / err)
          if (this.pw_needed) {
            this.password = ''
          }
        })
        .then(() => this.ajaxAlwaysEnd())
    },

    ajaxAlwaysStart () {
      this.loading = true
    },

    ajaxAlwaysEnd () {
      this.loading = false
    },
  }
}
</script>